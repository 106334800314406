import { inject, Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toastController = inject(ToastController);

  async showToast(options: ToastOptions) {
    const toast = await this.toastController.create({
      duration: 5000,
      message: '',
      position: 'top',
      buttons: [{ side: 'end', text: 'Ok' }],
      color: 'dark',
      htmlAttributes: { tabindex: undefined },
      ...options,
    });
    await toast.present();
    return toast;
  }
}
